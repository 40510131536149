import request from '@/utils/request';
import getConfig from 'next/config';
import { parseCookies } from 'nookies';
import { publish } from '@/utils/publish';

import { XCSRFHeader } from '@/utils/auth';

const { publicRuntimeConfig } = getConfig();

export const CURRENT_USER_INFO = 'CURRENT_USER_INFO';
export const PROFILE = 'PROFILE';
export const CSV = 'CSV';
export const FAVORITE_USERS = 'FAVORITE_USERS';
export const DASHBOARD = 'DASHBOARD';
export const RESET_DASHBOARD = 'RESET_DASHBOARD';
export const CHANGE_LAST_OTP_AT = 'CHANGE_LAST_OTP_AT';
export const SIGNUP = 'SIGNUP';
export const SEARCH_PHONE_NUMBERS = 'SEARCH_PHONE_NUMBERS';
export const API_KEYS = 'API_KEYS';
export const UPDATE_API_KEY = 'UPDATE_API_KEY';
export const DELETE_API_KEY = 'DELETE_API_KEY';
export const QUICK_FILTERS = 'QUICK_FILTERS';
export const SOA_REQUESTS = 'SOA_REQUESTS';
export const SOA_REQUEST = 'SOA_REQUEST';

export const fetchCurrentUser =
  (ctx = null) =>
  async dispatch => {
    const current_admin_token = ctx?.req && parseCookies(ctx)['current_admin_token'];
    const impersonator_token = ctx?.req && parseCookies(ctx)['impersonator_token'];
    const response = await request({
      path: `/v1/users/me`,
      token: ctx?.req && parseCookies(ctx)['token'],
      current_admin_token,
      impersonator_token
    });
    return dispatch({
      payload: response,
      type: CURRENT_USER_INFO
    });
  };

export const changeLastOtpAt = payload => async dispatch => {
  return dispatch({
    payload,
    type: CHANGE_LAST_OTP_AT
  });
};

export const updateTos = () => async () =>
  await request({
    path: `/v1/users/accept_tos`,
    method: 'PATCH'
  });

export const updateTimezone =
  ({ ctx, timezone }) =>
  async () =>
    await request({
      path: `/v1/users/update_timezone`,
      token: ctx?.req && parseCookies(ctx)['token'],
      method: 'PATCH',
      body: { timezone }
    });

export const updateTheme = body => async dispatch => {
  const response = await request({
    path: `/v1/users/update_theme`,
    method: 'PATCH',
    body
  });
  dispatch({
    payload: response,
    type: CURRENT_USER_INFO
  });
};

export const updateProfileFields = body => async dispatch => {
  const response = await request({
    path: `/v1/users/update_profile`,
    method: 'PATCH',
    body
  });
  dispatch({
    payload: response,
    type: CURRENT_USER_INFO
  });
};

export const updateUser = body => async dispatch => {
  const response = await request({
    path: `/v1/users/${body.id}`,
    method: 'PATCH',
    body
  });
  dispatch({
    payload: response,
    type: CURRENT_USER_INFO
  });
};

export const updateProfilePhoto =
  ({ id, personal_photo, editUser }) =>
  async dispatch => {
    const formDataBody = new FormData();
    formDataBody.append('personal_photo', personal_photo);
    const response = await request({
      path: `/v1/users/${id}/update_webpage`,
      method: 'PATCH',
      formData: true,
      formDataBody
    });
    if (!editUser) {
      dispatch({
        payload: response,
        type: CURRENT_USER_INFO
      });
    }
  };

export const updateProfile = body => async dispatch => {
  const response = await request({
    path: `/v1/users/${body.id}`,
    method: 'PATCH',
    body
  });
  dispatch({
    payload: response,
    type: CURRENT_USER_INFO
  });
};

export const updateProfileWebpage =
  (body, { editUser }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/users/${body.id}/update_webpage`,
      method: 'PATCH',
      body
    });
    if (!editUser) {
      dispatch({
        payload: response,
        type: CURRENT_USER_INFO
      });
    }
  };

export const updatePassword = body => async () =>
  await request({
    path: `/v1/users/${body.id}/update_password`,
    method: 'PATCH',
    body
  });

export const fetchApiKeys = () => async dispatch => {
  const response = await request({
    path: `/v1/api_keys`
  });
  dispatch({
    payload: response,
    type: API_KEYS
  });
};

export const fetchApiKey = body => async () =>
  await request({
    path: `/v1/api_keys/${body.id}`,
    method: 'POST',
    body
  });

export const createApiKey = body => async () =>
  await request({
    path: `/v1/api_keys`,
    method: 'POST',
    body
  });

export const updateApiKey = body => async dispatch => {
  const response = await request({
    path: `/v1/api_keys/${body.id}`,
    method: 'PATCH',
    body
  });
  dispatch({
    payload: response,
    type: UPDATE_API_KEY
  });
};

export const deleteApiKey = body => async dispatch => {
  await request({
    path: `/v1/api_keys/${body.id}`,
    method: 'DELETE',
    body
  });
  dispatch({
    payload: { data: { id: body.id } },
    type: DELETE_API_KEY
  });
};

export const fetchAPIKey = () => async () =>
  await request({
    path: `/v1/users/show_api_token`
  });

export const refreshAPIKey = () => async () =>
  await request({
    path: `/v1/users/refresh_api_token`,
    method: 'PATCH'
  });

function saveAsFile(text, filename) {
  // Step 1: Create the blob object with the text you received
  const type = 'text/csv'; // modify or get it from response
  const blob = new Blob(text, { type });

  // Step 2: Create Blob Object URL for that blob
  const url = URL.createObjectURL(blob);

  // Step 3: Trigger downloading the object using that URL
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click(); // triggering it manually
}
export const exportCSV = body => async () => {
  const url = `${publicRuntimeConfig.API_URL}/v1/export.${body.format}`;
  await fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: Object.assign({ 'Content-Type': 'application/json' }, XCSRFHeader()),
    credentials: 'include',
    responseType: 'stream'
  }).then(async response => {
    if (response.status === 200) {
      const filename = response.headers.get('content-disposition')?.split(';')[1]?.split('=')[1]?.replace(/"/g, '');
      const reader = response.body.getReader();
      const fileData = [];
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        if (body.format === 'csv') {
          fileData.push(new TextDecoder('utf-8').decode(value));
        }
        if (body.format === 'xlsx') {
          fileData.push(value);
        }
      }
      saveAsFile(fileData, filename);
    } else {
      publish('error', response.statusText);
    }
  });
};

export const exportCSV2 = body => async () => {
  const url = `${publicRuntimeConfig.API_URL}/v1/export.${body.format}`;

  const response = await fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: Object.assign({ 'Content-Type': 'application/json' }, XCSRFHeader()),
    credentials: 'include'
  });
  const blobType = response.headers.get('Content-Type');
  const filename = response.headers.get('Content-Disposition')?.split(';')[1]?.split('=')[1]?.replace(/"/g, '');
  const reader = response.body.getReader();
  const chunks = [];

  // eslint-disable-next-line no-inner-declarations
  async function process({ done, value }) {
    if (done) {
      const blob = new Blob(chunks, { type: blobType });
      const exportUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = filename;
      link.href = exportUrl;
      link.click();
      reader.releaseLock();
      return;
    }
    if (body.format === 'csv') {
      chunks.push(new TextDecoder('utf-8').decode(value));
    } else if (body.format === 'xlsx') {
      chunks.push(value);
    }
    return reader.read().then(process);
  }

  return await process(await reader.read());
};

export const addToFavoriteUsers = favorite_id => async () =>
  await request({
    path: `/v1/favorite_lists`,
    method: 'POST',
    body: { favorite_id }
  });

export const fetchFavoriteUsers = () => async dispatch => {
  const response = await request({
    path: `/v1/favorite_lists`
  });
  dispatch({
    payload: response,
    type: FAVORITE_USERS
  });
};

export const removeFromFavoritesUsers = favorite_id => async () =>
  await request({
    path: `/v1/favorite_lists/delete`,
    method: 'POST',
    body: { favorite_id }
  });

export const fetchDashboardInfo = body => async dispatch => {
  const response = await request({
    path: `/v1/dashboard?type=${body.type}`,
    method: 'PATCH',
    body
  });
  dispatch({
    payload: response,
    type: DASHBOARD
  });
};

export const resetDashboardInfo = () => async dispatch => dispatch({ type: RESET_DASHBOARD });

export const generateQRTwoFactorAuth = () => async () =>
  await request({
    path: `/v1/two_factor/generate_qr`,
    method: 'POST'
  });

export const enableTwoFactorAuth = body => async () =>
  await request({
    path: `/v1/two_factor/enable`,
    method: 'POST',
    body
  });

export const disableTwoFactorAuth = () => async () =>
  await request({
    path: `/v1/two_factor/disable`,
    method: 'POST'
  });

export const sendTwoFactorAuthCode = body => async () => {
  return await request({
    path: `/v1/two_factor/send_otp`,
    method: 'POST',
    body
  });
};

export const updateTwoFactor = () => async () =>
  await request({
    path: `/v1/users/update_2fa`,
    method: 'PATCH'
  });

export const fetchSignup = () => async dispatch => {
  const response = await request({
    path: `/v1/signups/fetch`
  });
  dispatch({
    payload: response,
    type: SIGNUP
  });
};

export const createSignup = body => async dispatch => {
  const response = await request({
    path: `/v1/signups`,
    method: 'POST',
    body
  });
  dispatch({
    payload: response,
    type: SIGNUP
  });
};

export const searchPhoneNumbers = body => async dispatch => {
  const response = await request({
    path: `/v1/users/search_phone_number`,
    method: 'GET',
    query: body
  });
  dispatch({
    payload: response.data,
    type: SEARCH_PHONE_NUMBERS
  });
};

export const purchasePhoneNumber = body => async () =>
  await request({
    path: `/v1/users/purchase_phone_number`,
    method: 'POST',
    body: body
  });

export const medicareRegistered = () => async () =>
  await request({
    path: `/v1/users/medicare_registered`
  });

export const fetchQuickFilters = () => async dispatch => {
  const response = await request({
    path: `/v1/quick_filters`
  });
  dispatch({
    payload: response,
    type: QUICK_FILTERS
  });
};

export const createQuickFilter = body => async () =>
  await request({
    path: `/v1/quick_filters`,
    method: 'POST',
    body
  });

export const updateQuickFilter = body => async () =>
  await request({
    path: `/v1/quick_filters/${body.id}`,
    method: 'PATCH',
    body
  });

export const deleteQuickFilter = id => async () =>
  await request({
    path: `/v1/quick_filters/${id}`,
    method: 'DELETE'
  });

export const fetchSoaRequests = query => async dispatch => {
  const response = await request({
    path: `/v1/soa_requests`,
    query
  });
  dispatch({
    payload: response,
    type: SOA_REQUESTS
  });
};

export const fetchSoaRequest = id => async dispatch => {
  const response = await request({
    path: `/v1/soa_requests/${id}`
  });
  dispatch({
    payload: response,
    type: SOA_REQUEST
  });
};

export const completeSoaRequest = (id, body) => async dispatch => {
  const response = await request({
    path: `/v1/soa_requests/${id}/complete`,
    method: 'POST',
    body
  });

  dispatch({
    payload: response,
    type: SOA_REQUEST
  });
};

export const rejectSoaRequest = id => async () =>
  await request({
    path: `/v1/soa_requests/${id}/reject`,
    method: 'POST'
  });
