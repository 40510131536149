import { SMS_NUMBERS, SMS_ORDERS, SMS_SUBSCIPTIONS, SMS_USAGE } from '@/store/actions/admin/sms.actions';

const initialState = {
  numbers: [],
  orders: [],
  subscriptions: [],
  usage: {}
};

export default function sms(state = initialState, action) {
  switch (action.type) {
    case SMS_NUMBERS:
      return {
        ...state,
        numbers: action.payload.data
      };
    case SMS_ORDERS:
      return {
        ...state,
        orders: action.payload.data
      };
    case SMS_SUBSCIPTIONS:
      return {
        ...state,
        subscriptions: action.payload.data
      };
    case SMS_USAGE:
      return {
        ...state,
        usage: action.payload.data
      };
    default:
      return state;
  }
}
