import request from '@/utils/request';

export const SMS_USAGE = 'SMS_USAGE';
export const SMS_SUBSCIPTIONS = 'SMS_SUBSCIPTIONS';
export const SMS_ORDERS = 'SMS_ORDERS';
export const SMS_NUMBERS = 'SMS_NUMBERS';

export const fetchUsage = () => async dispatch => {
  const response = await request({
    path: `/v1/admin/sms/usage`
  });
  dispatch({
    payload: response,
    type: SMS_USAGE
  });
};

export const fetchSubscriptions = () => async dispatch => {
  const response = await request({
    path: `/v1/admin/sms/subscriptions`
  });
  dispatch({
    payload: response,
    type: SMS_SUBSCIPTIONS
  });
};

export const updateSubscription = body => async () =>
  await request({
    path: `/v1/admin/sms/subscriptions`,
    method: 'POST',
    body
  });

export const activateSubscription = body => async () =>
  await request({
    path: `/v1/admin/sms/active_subscription`,
    method: 'POST',
    body
  });

export const cancelSubscription = body => async () =>
  await request({
    path: `/v1/admin/sms/cancel_subscription`,
    method: 'POST',
    body
  });

export const fetchOrders = body => async dispatch => {
  const response = await request({
    path: `/v1/admin/sms/orders`
  });
  dispatch({
    payload: response,
    type: SMS_ORDERS
  });
};

export const updateOrder = body => async () =>
  await request({
    path: `/v1/admin/sms/orders`,
    method: 'POST',
    body
  });

export const cancelOrder = body => async () =>
  await request({
    path: `/v1/admin/sms/cancel_order`,
    method: 'POST',
    body
  });

export const fetchNumbers = () => async dispatch => {
  const response = await request({
    path: `/v1/admin/sms/numbers`
  });
  dispatch({
    payload: response,
    type: SMS_NUMBERS
  });
};
