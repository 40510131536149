import request from '@/utils/request';

export const DIALER_USAGE = 'DIALER_USAGE';
export const DIALER_SUBSCIPTIONS = 'DIALER_SUBSCIPTIONS';
export const DIALER_ORDERS = 'DIALER_ORDERS';

export const fetchUsage = () => async dispatch => {
  const response = await request({
    path: `/v1/admin/dialer/usage`
  });
  dispatch({
    payload: response,
    type: DIALER_USAGE
  });
};

export const fetchSubscriptions = () => async dispatch => {
  const response = await request({
    path: `/v1/admin/dialer/subscriptions`
  });
  dispatch({
    payload: response,
    type: DIALER_SUBSCIPTIONS
  });
};

export const updateSubscription = body => async () => {
  const response = await request({
    path: `/v1/admin/dialer/subscriptions`,
    method: 'POST',
    body
  });
  return response;
};

export const activateSubscription = body => async () => {
  const response = await request({
    path: `/v1/admin/dialer/active_subscription`,
    method: 'POST',
    body
  });
  return response;
};

export const cancelSubscription = body => async () => {
  const response = await request({
    path: `/v1/admin/dialer/cancel_subscription`,
    method: 'POST',
    body
  });
  return response;
};

export const fetchOrders = body => async dispatch => {
  const response = await request({
    path: `/v1/admin/dialer/orders`
  });
  dispatch({
    payload: response,
    type: DIALER_ORDERS
  });
};

export const updateOrder = body => async () => {
  const response = await request({
    path: `/v1/admin/dialer/orders`,
    method: 'POST',
    body
  });
  return response;
};

export const cancelOrder = body => async () => {
  const response = await request({
    path: `/v1/admin/dialer/cancel_order`,
    method: 'POST',
    body
  });
  return response;
};
