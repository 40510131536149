import { DIALER_ORDERS, DIALER_SUBSCIPTIONS, DIALER_USAGE } from '@/store/actions/admin/dialer.actions';

const initialState = {
  orders: [],
  subscriptions: [],
  usage: {}
};

export default function dialer(state = initialState, action) {
  switch (action.type) {
    case DIALER_ORDERS:
      return {
        ...state,
        orders: action.payload.data
      };
    case DIALER_SUBSCIPTIONS:
      return {
        ...state,
        subscriptions: action.payload.data
      };
    case DIALER_USAGE:
      return {
        ...state,
        usage: action.payload.data
      };
    default:
      return state;
  }
}
