import request from '@/utils/request';
import getConfig from 'next/config';
import { getSessionItem, setSessionItem, removeSessionItem } from '@/utils/storage';
import { CONTACT_EVENTS } from '@/store/actions/event.actions';
import { XCSRFHeader } from '@/utils/auth';

const { publicRuntimeConfig } = getConfig();

export const CONTACT = 'CONTACT';
export const CONTACTS = 'CONTACTS';
export const CONTACTS_LOAD_MORE = 'CONTACTS_LOAD_MORE';
export const CONTACTS_META = 'CONTACTS_META';
export const CONTACTS_MERGE = 'CONTACTS_MERGE';
export const CONTACTS_SELECTED_ITEMS = 'CONTACTS_SELECTED_ITEMS';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const DELETE_CONTACTS = 'DELETE_CONTACTS';
export const NOTES = 'NOTES';
export const CREATE_NOTES = 'CREATE_NOTES';
export const UPDATE_NOTES = 'UPDATE_NOTES';
export const FILES = 'FILES';
export const TAGS = 'TAGS';
export const NEW_TAG = 'NEW_TAG';
export const DATA_CAPTURE = 'DATA_CAPTURE';
export const CLEAR_DATA_CAPTURE = 'CLEAR_DATA_CAPTURE';
export const POLICIES = 'POLICIES';
export const SECURITIES = 'SECURITIES';
export const PARTICULAR_POLICY = 'PARTICULAR_POLICY';
export const TRANSFER_GROUP = 'TRANSFER_GROUP';
export const CLEAR_TRANSFER_GROUP = 'CLEAR_TRANSFER_GROUP';
export const MAILER_ASSIGNMENT_KITS = 'MAILER_ASSIGNMENT_KITS';
export const MAILER_ASSIGNMENT_COUNTS = 'MAILER_ASSIGNMENT_COUNTS';
export const IMPORT_FILE = 'IMPORT_FILE';
export const IMPORT_ATTACHMENTS = 'IMPORT_ATTACHMENTS';
export const IMPORT_FORM = 'IMPORT_FORM';
export const IMPORT_ATTEMPTS = 'IMPORT_ATTEMPTS';
export const EMAIL_LOGS = 'EMAIL_LOGS';
export const MAILING_GROUP = 'MAILING_GROUP';
export const EMAILS = 'EMAILS';
export const EMAIL_CAMPAIGN_TRIGGERS = 'EMAIL_CAMPAIGN_TRIGGERS';
export const EMAIL_HISTORY = 'EMAIL_HISTORY';
export const CALENDAR_HISTORY = 'CALENDAR_HISTORY';
export const TRANSFER_HISTORY = 'TRANSFER_HISTORY';
export const SET_CONTACT_TO_STORE = 'SET_CONTACT_TO_STORE';
export const SEND_EMAIL = 'SEND_EMAIL';
export const OPEN_PROCESS_NOTIFICATION = 'OPEN_PROCESS_NOTIFICATION';
export const CLOSE_PROCESS_NOTIFICATION = 'CLOSE_PROCESS_NOTIFICATION';
export const WORKERS_STATUS = 'WORKERS_STATUS';
export const CONTACT_STATUSES = 'CONTACT_STATUSES';
export const CONTACT_SOURCES = 'CONTACT_SOURCES';
export const CALL_FILES = 'CALL_FILES';
export const MESSAGES = 'MESSAGES';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SMS_GROUP = 'SMS_GROUP';
export const CONTACTS_CAMPAIGN_TRIGGERS = 'CONTACTS_CAMPAIGN_TRIGGERS';
export const NEW_TRANSFER_ATTEMPT = 'NEW_TRANSFER_ATTEMPT';
export const TRANSFER_ATTEMPT_STATUS = 'TRANSFER_ATTEMPT_STATUS';
export const CLEAR_TRANSFER_ATTEMPT = 'CLEAR_TRANSFER_ATTEMPT';
export const OPTIMISTIC_UPDATE_TRANSFER_ATTEMPT = 'OPTIMISTIC_UPDATE_TRANSFER_ATTEMPT';
export const RX_DETAILS = 'RX_DETAILS';
export const PROCESSING_IMPORT_ATTEMPTS = 'PROCESSING_IMPORT_ATTEMPTS';
export const CSG_PLANS = 'CSG_PLANS';
export const CSG_PLAN_DETAILS = 'CSG_PLAN_DETAILS';
export const CSG_QUOTE = 'CSG_QUOTE';

export const fetchContacts =
  (body, additionalParams = {}) =>
  async dispatch => {
    if (body) setSessionItem('fetchContactsParams', JSON.stringify(body));
    await request({
      path: `/v1/contacts`,
      method: 'PATCH',
      body: {
        contacts_scope: { ...JSON.parse(getSessionItem('fetchContactsParams')), ...additionalParams, select_n: false }
      }
    }).then(response => {
      let type = CONTACTS;
      switch (additionalParams?.type) {
        case 'meta':
          type = CONTACTS_META;
          break;
        case 'load_more':
          type = CONTACTS_LOAD_MORE;
          break;
      }
      dispatch({
        payload: response,
        type
      });
    });
  };

export const fetchMergeContacts = () => async dispatch => {
  const params = JSON.parse(getSessionItem('fetchContactsParams'));
  // do not fetch Merge contacts if category is All // performance issues
  if (params?.contact_group === 'all' || params?.contact_group === 'deleted') {
    dispatch({
      payload: { data: [] },
      type: CONTACTS_MERGE
    });
    return;
  }
  const response = await request({
    path: `/v1/contacts`,
    method: 'PATCH',
    body: {
      contacts_scope: {
        search: [],
        duplicate: {
          is_duplicate: true,
          fields: {
            first_name: true,
            last_name: true,
            address: true
          }
        },
        page: 1,
        limit: 50,
        q: '',
        order: params?.order || 'ASC',
        order_field: params?.order_field || 'last_name',
        category: params?.category || null,
        all_downline: params?.all_downline || false,
        contact_group: params?.contact_group || 'downline',
        pool_id: params?.pool_id || 'mine',
        downline_user_id: params?.downline_user_id || 1,
        select_n: false,
        type: 'data',
        representer_type: 'duplicate_merge'
      }
    }
  });
  dispatch({
    payload: response,
    type: CONTACTS_MERGE
  });
};

export const fetchSelectedContacts =
  ({ ids, limit }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/contacts/selected_items`,
      method: 'POST',
      body: { ids, limit }
    });
    dispatch({
      payload: response,
      type: CONTACTS_SELECTED_ITEMS
    });
  };

export const clearContacts = () => async dispatch => {
  removeSessionItem('contactsPage');
  dispatch({
    payload: { data: [] },
    type: CONTACTS
  });
};

export const clearContact = () => async dispatch => {
  dispatch({
    payload: { data: [] },
    type: CONTACT_EVENTS
  });
  dispatch({
    payload: { data: [] },
    type: FILES
  });
};

export const updateContactNote = body => async dispatch => {
  const response = await request({
    path: `/v1/contacts/${body.id}/update_note`,
    method: 'PATCH',
    body
  });
  dispatch({
    payload: response,
    type: UPDATE_CONTACT
  });
};

export const quickContactUpdate = body => async () =>
  await request({
    path: `/v1/contacts/${body.id}/quick_update`,
    method: 'PATCH',
    body: body.data
  });

export const updateContact = body => async dispatch => {
  const response = await request({
    path: `/v1/contacts/${body.id}`,
    method: 'PATCH',
    body: body.data
  });

  dispatch({
    payload: response,
    type: UPDATE_CONTACT
  });
};

export const forceVerifyEmail = body => async dispatch => {
  const response = await request({
    path: `/v1/contacts/${body.id}/force_verify_email`,
    method: 'PATCH',
    body: body.data
  });
  dispatch({ payload: response, type: UPDATE_CONTACT });
};

export const mergeContacts = body => async () =>
  await request({
    path: `/v1/contacts/merge`,
    method: 'PATCH',
    body
  });

export const fetchDataCapture = id => async dispatch => {
  const response = await request({
    path: `/v1/contacts/${id}/data_capture`
  });

  dispatch({
    payload: response,
    type: DATA_CAPTURE
  });
};

export const updateDataCapture = body => async () =>
  await request({
    path: `/v1/contacts/${body.id}/data_capture`,
    method: 'PATCH',
    body: { data_captures: body.data }
  });

export const fetchTags =
  ({ q = null, additionalParams } = {}) =>
  async dispatch => {
    let body = { q, ...additionalParams };
    if (!additionalParams?.isMineTags && !additionalParams?.skipContactsScope) {
      body = { ...body, contacts_scope: { ...JSON.parse(getSessionItem('fetchContactsParams')) } };
    }
    const response = await request({
      path: `/v1/tags`,
      method: 'PATCH',
      body
    });
    dispatch({
      payload: response,
      type: TAGS
    });
  };

export const createContactTag = (name, color, id) => async dispatch => {
  const response = await request({
    path: `/v1/tags`,
    method: 'POST',
    body: { tag_objects: [{ contact_id: id }], name, color }
  });
  dispatch({
    payload: response,
    type: UPDATE_CONTACT
  });
};

export const createTag = (name, color) => async dispatch => {
  const response = await request({
    path: `/v1/tags`,
    method: 'POST',
    body: { name, color }
  });
  dispatch({
    payload: response,
    type: NEW_TAG
  });
};

export const attachTags = (tags, contactIds, all) => async () =>
  await request({
    path: `/v1/contacts/update_tags_group`,
    method: 'PATCH',
    body: { tags, ids: contactIds, all, contacts_scope: JSON.parse(getSessionItem('fetchContactsParams')) }
  });

export const attachTagsSingle = (tags, id) => async dispatch => {
  const response = await request({
    path: `/v1/contacts/update_tags_single`,
    method: 'PATCH',
    body: { tags, id }
  });
  dispatch({
    payload: response,
    type: UPDATE_CONTACT
  });
};

export const deleteContacts = body => async dispatch => {
  const response = await request({
    path: `/v1/contacts/destroy_all`,
    method: 'DELETE',
    body: { ...body, contacts_scope: JSON.parse(getSessionItem('fetchContactsParams')) }
  });
  if (body.ids?.length) {
    dispatch({
      payload: { ids: body.ids },
      type: DELETE_CONTACTS
    });
  }
};

export const fetchContact =
  (id, { not_remove_new_tag = false } = {}) =>
  async dispatch => {
    const response = await request({
      path: `/v1/contacts/${id}`,
      query: { not_remove_new_tag }
    });
    dispatch({
      payload: response,
      type: CONTACT
    });
    dispatch({
      payload: response,
      type: UPDATE_CONTACT
    });
  };

export const restoreContact =
  ({ ids, all = false }) =>
  async () =>
    await request({
      path: `/v1/contacts/restore`,
      method: 'PUT',
      body: { ids, all, contacts_scope: JSON.parse(getSessionItem('fetchContactsParams')) }
    });

export const fetchNotes = data => async dispatch => {
  const response = await request({
    path: `/v1/contacts/${data.id}/notes`,
    query: { q: data.q }
  });
  dispatch({
    payload: response,
    type: NOTES
  });
};

export const createContactNote =
  ({ id, body }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/contacts/${id}/notes`,
      method: 'POST',
      body: { body }
    });
    dispatch({
      payload: response,
      type: UPDATE_CONTACT
    });
  };

export const deleteNotes = (contactId, noteId) => async dispatch => {
  const response = await request({
    path: `/v1/contacts/${contactId}/notes/${noteId}`,
    method: 'DELETE'
  });
  dispatch({
    payload: response,
    type: UPDATE_CONTACT
  });
};

export const updateContactNotes = data => async dispatch => {
  const response = await request({
    path: `/v1/contacts/${data.contactId}/notes/${data.noteId}`,
    method: 'PATCH',
    body: { body: data.body }
  });

  dispatch({
    payload: response,
    type: UPDATE_CONTACT
  });
};

export const createContact = data => async dispatch => {
  const response = await request({
    path: `/v1/contacts`,
    method: 'POST',
    body: data
  });
  await dispatch({
    payload: response,
    type: CONTACT
  });
  await dispatch(clearContacts());
  return response;
};

export const uploadContactFile =
  ({ id, file }) =>
  async dispatch => {
    const formDataBody = new FormData();
    formDataBody.append('file', file);
    const response = await request({
      path: `/v1/contacts/${id}/files`,
      method: 'POST',
      formData: true,
      formDataBody
    });
    dispatch({
      payload: response,
      type: UPDATE_CONTACT
    });
  };

export const uploadContactOptFile =
  ({ id, file }) =>
  async () => {
    const formDataBody = new FormData();
    formDataBody.append('file', file);
    const response = await request({
      path: `/v1/contacts/${id}/files`,
      method: 'POST',
      formData: true,
      formDataBody
    });
    return response;
  };

export const fetchContactFiles = (id, q, limit, page, order, order_field) => async dispatch => {
  const response = await request({
    path: `/v1/contacts/${id}/files`,
    query: { q, limit, page, order, order_field }
  });
  dispatch({
    payload: response,
    type: FILES
  });
};

export const deleteContactFile =
  ({ contactId, fileId }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/contacts/${contactId}/files/${fileId}`,
      method: 'DELETE'
    });
    dispatch({
      payload: response,
      type: UPDATE_CONTACT
    });
  };

export const updateContactGroup = data => async () =>
  await request({
    path: `/v1/contacts/update_group`,
    method: 'PATCH',
    body: { ...data, contacts_scope: JSON.parse(getSessionItem('fetchContactsParams')) }
  });

export const createPolicy =
  ({ id, data }) =>
  async () =>
    await request({
      path: `/v1/contacts/${id}/policies`,
      method: 'POST',
      body: data
    });

export const fetchPolicies = ({ id, page, limit }) => {
  return async dispatch => {
    dispatch({
      payload: { data: [], meta: {} },
      type: POLICIES
    });
    const response = await request({
      path: `/v1/contacts/${id}/policies`,
      query: { id: id, limit: limit, page: page }
    });
    dispatch({
      payload: response,
      type: POLICIES
    });
  };
};

export const updatePolicy =
  ({ id, data, policy_id }) =>
  async () =>
    await request({
      path: `/v1/contacts/${id}/policies/${policy_id}`,
      method: 'PATCH',
      body: data
    });

export const deletePolicy =
  ({ id, policy_id }) =>
  async () =>
    await request({
      path: `/v1/contacts/${id}/policies/${policy_id}`,
      method: 'DELETE'
    });

export const fetchParticularPolicy =
  ({ id, policy_id }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/contacts/${id}/policies/${policy_id}`
    });
    dispatch({
      payload: response,
      type: PARTICULAR_POLICY
    });
  };

export const fetchTransferGroup =
  ({ ids, all }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/contacts/transfer_group`,
      method: 'PATCH',
      body: { ids, all, contacts_scope: JSON.parse(getSessionItem('fetchContactsParams')) }
    });
    dispatch({
      payload: response,
      type: TRANSFER_GROUP
    });
  };

export const fetchMailerAssignmentKits = () => async dispatch => {
  const response = await request({
    path: `/v1/contacts/mailer_assignment_kits`
  });
  dispatch({
    payload: response,
    type: MAILER_ASSIGNMENT_KITS
  });
};

export const fetchMailerAssignmentCounts = query => async dispatch => {
  const response = await request({
    path: `/v1/contacts/mailer_assignment_counts`,
    query
  });
  dispatch({
    payload: response,
    type: MAILER_ASSIGNMENT_COUNTS
  });
};

export const createMailerAssignment = body => async () =>
  await request({
    path: `/v1/contacts/mailer_assignment_create`,
    method: 'POST',
    body
  });

export const clearTransferGroup = () => ({ type: CLEAR_TRANSFER_GROUP });

export const transferContactsGroup = data => async dispatch => {
  const response = await request({
    path: `/v1/contacts/update_transfer_group`,
    method: 'PATCH',
    body: { ...data, contacts_scope: JSON.parse(getSessionItem('fetchContactsParams')) }
  });
  dispatch({ payload: response, type: NEW_TRANSFER_ATTEMPT });
  return response;
};

export const fetchTransferAttemptStatus =
  ({ id }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/contacts/transfer_attempts/${id}/status`
    });
    if (response.data?.id) {
      dispatch({ payload: response, type: TRANSFER_ATTEMPT_STATUS });
    }
    return response;
  };

export const clearTransferAttempt = () => ({ type: CLEAR_TRANSFER_ATTEMPT });

export const optimisticUpdateTransferAttempt =
  ({ id, revert = false }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/contacts/transfer_attempts/${id}`,
      method: 'PATCH',
      body: { revert }
    });
    if (response.data) {
      dispatch({
        payload: { id, revert, updated_at: response.data.updated_at },
        type: OPTIMISTIC_UPDATE_TRANSFER_ATTEMPT
      });
    }
    return response;
  };

export const saveImportFileToProps = file => async dispatch =>
  dispatch({
    payload: file,
    type: IMPORT_FILE
  });

export const saveImportAttachmentsToProps = files => async dispatch =>
  dispatch({
    payload: files,
    type: IMPORT_ATTACHMENTS
  });

export const saveImportForm = form => async dispatch =>
  dispatch({
    payload: form,
    type: IMPORT_FORM
  });

export const validateImportContacts = () => async (dispatch, getState) => {
  const formDataBody = importData({ getState });
  await request({
    path: `/v1/contacts/validate_import`,
    method: 'POST',
    formData: true,
    formDataBody
  });
};

export const uploadImportAttachmentsContacts =
  ({ id: id, import_file: import_file, attachment: attachment }) =>
  async (dispatch, getState) => {
    const formDataBody = new FormData();
    if (import_file) formDataBody.append('import_file', import_file);
    if (attachment) {
      formDataBody.append('id', id);
      formDataBody.append('import_attachment', attachment);
    }
    return await request({
      path: `/v1/contacts/import_attachments`,
      method: 'POST',
      formData: true,
      formDataBody
    });
  };

export const performImportContacts =
  ({ id: id }) =>
  async (dispatch, getState) => {
    const formDataBody = importData({ getState });
    formDataBody.append('id', id);
    await request({
      path: `/v1/contacts/import`,
      method: 'POST',
      formData: true,
      formDataBody
    });
  };

export const uploadLeadsAttachmentsContacts =
  ({ id: id, attachment: attachment } = {}) =>
  async (dispatch, getState) => {
    const formDataBody = new FormData();
    if (attachment) {
      formDataBody.append('id', id);
      formDataBody.append('import_attachment', attachment);
    }
    return await request({
      path: `/v1/contacts/attachments_leads_uploader`,
      method: 'POST',
      formData: attachment ? true : false,
      formDataBody
    });
  };

export const performLeadsUploader =
  ({ id: id, importSettingId: importSettingId }) =>
  async (dispatch, getState) => {
    const formDataBody = new FormData();
    formDataBody.append('id', id);
    formDataBody.append('import_setting_id', importSettingId);
    return await request({
      path: `/v1/contacts/leads_uploader`,
      method: 'POST',
      formData: true,
      formDataBody
    });
  };

export const performClientsUploader =
  ({ import_type, import_file }) =>
  async () => {
    const formDataBody = new FormData();
    formDataBody.append('import_type', import_type);
    formDataBody.append('import_file', import_file);
    await request({
      path: `/v1/contacts/clients_uploader`,
      method: 'POST',
      formData: true,
      formDataBody
    });
  };

const importData = ({ getState: getState }) => {
  const formDataBody = new FormData();
  const importForm = getState().contacts.import_form;

  const importFormCopy = JSON.parse(JSON.stringify(importForm));

  const data = {
    ...importFormCopy,
    owner: importFormCopy.owner[0] && importFormCopy.owner[0].user.id,
    tags: importFormCopy.tags.map(el => {
      return {
        name: el.name,
        color: el.color
      };
    }),
    pool: importFormCopy.pool && importFormCopy.pool.value,
    scrub_against: {
      ...importFormCopy.scrub_against,
      updated_lead_tags: importFormCopy.scrub_against.updated_lead_tags.map(el => {
        return {
          name: el.name,
          color: el.color
        };
      })
    }
  };
  data.fields.forEach(field => {
    delete field.preview;
  });
  formDataBody.append('import_form', JSON.stringify(data));
  return formDataBody;
};

export const fetchImportAttempts = (limit, page, filter_by, filter_value) => async dispatch => {
  const response = await request({
    path: `/v1/contacts/import_attempts`,
    query: { limit, page, filter_by, filter_value }
  });
  if (response.data) {
    dispatch({
      payload: response,
      type: IMPORT_ATTEMPTS
    });
  }
  return response;
};

export const fetchImportSettings = () => async () =>
  await request({
    path: `/v1/import_settings`
  });

export const deleteImportSetting = id => async () =>
  request({
    path: `/v1/import_settings/${id}`,
    method: 'DELETE'
  });

export const createImportSetting =
  ({ name }) =>
  async (dispatch, getState) => {
    const data = importData({ getState, withFiles: false });
    await request({
      path: `/v1/import_settings`,
      method: 'POST',
      body: {
        name,
        data: JSON.parse(data.get('import_form'))
      }
    });
  };

export const fetchEmailLogs = id => async dispatch => {
  const response = await request({
    path: `/v1/contacts/${id}/email_logs`,
    query: { limit: 16 }
  });
  dispatch({
    payload: response,
    type: EMAIL_LOGS
  });
};

export const sendPersonalEmail =
  ({ id, email_body, subject }) =>
  async () =>
    await request({
      path: `/v1/contacts/${id}/send_email`,
      method: 'POST',
      body: { email_body, subject }
    });

export const fetchMailingGroup = (ids, all, select_n) => async dispatch => {
  const response = await request({
    path: `/v1/contacts/mailing_group`,
    method: 'POST',

    body: {
      ids,
      all,
      contacts_scope: { ...JSON.parse(getSessionItem('fetchContactsParams'), select_n) }
    }
  });
  dispatch({
    payload: response,
    type: MAILING_GROUP
  });
};

export const sendTestEmail =
  ({ email_id, email_campaign_trigger_id, contact_id, all }) =>
  async () =>
    await request({
      path: `/v1/contacts/send_test_email`,
      method: 'POST',
      body: { email_id, email_campaign_trigger_id, contact_id, all }
    });

export const sendBroadcatsEmail =
  ({ email_id, email_campaign_trigger_id, send_now, send_at, ids, all }) =>
  async () => {
    await request({
      path: `/v1/contacts/send_broadcast_email`,
      method: 'POST',
      body: {
        email_id,
        email_campaign_trigger_id,
        send_now,
        send_at,
        ids,
        all,
        contacts_scope: JSON.parse(getSessionItem('fetchContactsParams'))
      }
    });
  };

export const fetchEmails =
  (q = '') =>
  async dispatch => {
    const response = await request({
      path: `/v1/contacts/emails`,
      query: { q }
    });
    dispatch({
      payload: response,
      type: EMAILS
    });
  };

export const fetchEmailCampaignTriggers =
  (q = '') =>
  async dispatch => {
    const response = await request({
      path: `/v1/contacts/email_campaign_triggers`,
      query: { q }
    });
    dispatch({
      payload: response,
      type: EMAIL_CAMPAIGN_TRIGGERS
    });
  };

export const assignToMe = id => async () =>
  await request({
    path: `/v1/contacts/${id}/assign_to_me`,
    method: 'PATCH'
  });

export const activityInfo =
  ({ id, type }) =>
  async () =>
    await request({
      path: `/v1/contacts/${id}/activity_info`,
      method: 'PUT',
      body: { type }
    });

export const fetchEmailHistory =
  ({ id, event, limit, q, offset, order_type, sender_id, order_field, start_date, end_date }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/contacts/${id}/email_history`,
      method: 'POST',
      body: { limit, order_field, event, q, offset, order_type, sender_id, start_date, end_date }
    });
    dispatch({
      payload: response,
      type: EMAIL_HISTORY
    });
  };

export const fetchTransferHistory =
  ({ id, limit, q, page, order_field, order }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/contacts/${id}/transfer_history`,
      query: { limit, order_field, order, q, page }
    });
    dispatch({
      payload: response,
      type: TRANSFER_HISTORY
    });
  };

export const fetchCalendarEventsHistory =
  ({ limit, q, page }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/events/calendar_history`,
      query: { limit, q, page }
    });
    dispatch({
      payload: response,
      type: CALENDAR_HISTORY
    });
  };

export const setSearchContactToStore = search => async dispatch =>
  dispatch({
    payload: search,
    type: SET_CONTACT_TO_STORE
  });

export const changeProcessNotification =
  ({ visible = true, status, text, linkUrl, linkText }) =>
  async dispatch =>
    dispatch({
      payload: { visible, status, text, linkUrl, linkText },
      type: OPEN_PROCESS_NOTIFICATION
    });

export const closeProcessNotification =
  ({ status = 'processing' }) =>
  async dispatch =>
    dispatch({
      payload: { status },
      type: CLOSE_PROCESS_NOTIFICATION
    });

export const fetchWorkersStatus = () => async dispatch => {
  const response = await request({
    path: `/v1/contacts/workers_status`
  });
  dispatch({
    payload: response,
    type: WORKERS_STATUS
  });
};

export const firelightSubmissions =
  (agentData = {}) =>
  async () => {
    return await request({
      path: `/v1/fire_light/dashboard`,
      method: 'POST',
      body: { agent_data: agentData }
    });
  };

export const firelightSubmission =
  (body = {}) =>
  async () => {
    return await request({
      path: `/v1/fire_light/application`,
      method: 'POST',
      body
    });
  };

export const fetchStatuses = q => async dispatch => {
  const response = await request({
    path: `/v1/statuses`,
    query: q
  });
  dispatch({
    payload: response,
    type: CONTACT_STATUSES
  });
};

export const fetchSources = q => async dispatch => {
  const response = await request({
    path: `/v1/sources`,
    query: q
  });
  dispatch({
    payload: response,
    type: CONTACT_SOURCES
  });
};

export const fetchSecurities = ({ contactId, limit, page } = {}) => {
  return async dispatch => {
    const response = await request({
      path: `/v1/contacts/${contactId}/securities/`,
      query: { limit: +limit, page: page }
    });
    dispatch({
      payload: response,
      type: SECURITIES
    });
  };
};

export const createSecurities =
  ({ contactId, data } = {}) =>
  async () =>
    await request({
      path: `/v1/contacts/${contactId}/securities`,
      method: 'POST',
      body: { ...data }
    });

export const updateSecurities =
  ({ contactId, securityId, data } = {}) =>
  async () =>
    await request({
      path: `/v1/contacts/${contactId}/securities/${securityId}`,
      method: 'PATCH',
      body: data
    });

export const deleteSecurities =
  ({ contactId, securityId }) =>
  async () =>
    await request({
      path: `/v1/contacts/${contactId}/securities/${securityId}`,
      method: 'DELETE'
    });

export const getCustomIds =
  (body, additionalParams = {}) =>
  async () => {
    return await request({
      path: `/v1/contacts/select_first_last`,
      method: 'PATCH',
      body: {
        contacts_scope: { ...body },
        ...additionalParams
      }
    });
  };

export const selectN = data => async dispatch => {
  dispatch({
    payload: data,
    type: CONTACT_SOURCES
  });
};

export const enrollAftercare = id => async () => {
  return await request({
    path: `/v1/aftercares/${id}/enroll`,
    method: 'POST'
  });
};

export const checkFamilyStatus = id => async () =>
  await request({
    path: `/v1/aftercares/${id}/family_status`,
    method: 'GET'
  });

export const deleteImportAttempt = id => async () =>
  await request({
    path: `/v1/contacts/delete_import_attempts/${id}`,
    method: 'DELETE'
  });

export const retryImportAttempt = id => async () =>
  await request({
    path: `/v1/contacts/retry_failed_import_attempts/${id}`,
    method: 'POST'
  });

export const updateProcessingImportAttempts = data => async dispatch =>
  dispatch({
    payload: data,
    type: PROCESSING_IMPORT_ATTEMPTS
  });

export const fetchContactCallFiles = (id, q, limit, page, order, order_field) => async dispatch => {
  const response = await request({
    path: `/v1/contacts/${id}/call_files`,
    query: { q, limit, page, order, order_field }
  });
  dispatch({
    payload: response,
    type: CALL_FILES
  });
};

export const updateLinkedContact = body => async dispatch => {
  const response = await request({
    path: `/v1/contacts/${body.contact_id}/update_linked_contact`,
    method: 'PATCH',
    body
  });

  dispatch({
    payload: response,
    type: UPDATE_CONTACT
  });
};

export const fetchRxDetails = contact_id => async dispatch => {
  const response = await request({
    path: `/v1/contacts/${contact_id}/rx_details`
  });

  dispatch({
    payload: response,
    type: RX_DETAILS
  });
};

export const updateRxDetails = (contact_id, body) => async dispatch => {
  const response = await request({
    path: `/v1/contacts/${contact_id}/rx_details`,
    method: 'PATCH',
    body
  });

  dispatch({
    payload: response,
    type: RX_DETAILS
  });
};

export const fetchSmsGroup = (ids, all, select_n) => async dispatch => {
  const response = await request({
    path: `/v1/contacts/sms_group`,
    method: 'POST',
    body: {
      ids,
      all,
      contacts_scope: {
        ...JSON.parse(getSessionItem('fetchContactsParams'), select_n)
      }
    }
  });
  dispatch({
    payload: response,
    type: SMS_GROUP
  });
};

export const fetchBulkSmsVerificationGroup = body => async () =>
  await request({
    path: `/v1/contacts/bulk_sms_verification_group`,
    method: 'POST',
    body
  });

export const sendTestSms = (campaign_trigger_id, contact_id, all) => async () =>
  await request({
    path: `/v1/contacts/send_test_sms`,
    method: 'POST',
    body: { campaign_trigger_id, contact_id, all }
  });

export const sendBroadcastSms =
  ({ campaign_trigger_id, recurrence_campaign_trigger_id, send_now, send_at, ids, all }) =>
  async () => {
    await request({
      path: `/v1/contacts/send_broadcast_sms`,
      method: 'POST',
      body: {
        campaign_trigger_id,
        recurrence_campaign_trigger_id,
        send_now,
        send_at,
        ids,
        all,
        contacts_scope: JSON.parse(getSessionItem('fetchContactsParams'))
      }
    });
  };

export const fetchSmsCampaignTriggers = query => async dispatch => {
  const response = await request({
    path: `/v1/contacts/sms_campaign_triggers`,
    query
  });
  dispatch({
    payload: response,
    type: CONTACTS_CAMPAIGN_TRIGGERS
  });
};

export const batchPrintContactFiles = async ({ ids, all, contacts_scope }) => {
  return await fetch(`${publicRuntimeConfig.API_URL}/v1/contacts/batch_print`, {
    method: 'POST',
    headers: Object.assign({ 'Content-Type': 'application/json', Accept: 'application/json' }, XCSRFHeader()),
    body: JSON.stringify({ ids, all, contacts_scope }),
    credentials: 'include'
  });
};

export const batchPrintStatus = async ({ jid }) => {
  return await request({
    path: `/v1/contacts/batch_print_status`,
    method: 'PATCH',
    body: { jid }
  });
};

export const fetchCSGPlans = body => async dispatch => {
  const response = await request({
    path: '/v1/csg/plans',
    method: 'POST',
    body
  });
  dispatch({
    payload: response,
    type: CSG_PLANS
  });
};

export const fetchCSGPlanDetails = body => async dispatch => {
  const response = await request({
    path: '/v1/csg/plan_details',
    method: 'POST',
    body
  });
  dispatch({
    payload: response,
    type: CSG_PLAN_DETAILS
  });
};

export const fetchCSGQuote = body => async dispatch => {
  const response = await request({
    path: '/v1/csg/quote',
    method: 'POST',
    body
  });
  dispatch({
    payload: response,
    type: CSG_QUOTE
  });
};

export const clearCSGQuote = () => async dispatch => {
  dispatch({
    payload: { data: [] },
    type: CSG_QUOTE
  });
};

export const medicareImportContact = id => () =>
  request({
    path: `/v1/contacts/${id}/medicare_import_contact`,
    method: 'PUT'
  });
