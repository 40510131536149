import Head from 'next/head';
import React from 'react';
import App from 'next/app';
import { Provider } from 'react-redux';
import withRedux from 'next-redux-wrapper';
import { initStore } from '@/store/store';
import { setCookie, parseCookies, destroyCookie } from 'nookies';
import getConfig from 'next/config';
import * as Sentry from '@sentry/nextjs';
import Dialer from '@/components/Dialer/Dialer';
import HelpButton from '@/components/HelpButton';
import WebSocketConnection from '@/components/WebSocketConnection';
import { UserProvider } from '@auth0/nextjs-auth0/client';

import '@/styles/app.scss';

import { TIMEZONE_LIST } from '@/store/models/profile';
import { fetchCurrentUser, updateTimezone } from '@/store/actions/currentUser.actions';
import { fetchCurrentAccount } from '@/store/actions/currentAccount.actions';
import { fetchActiveFeatureFlag } from '@/store/actions/admin/users.actions';
import { handleUnauthorizedError } from '@/utils/auth';
import { health, fetchWebpageInfo } from '@/store/actions/public.actions';
import { config } from '@/config';

import { subscribe, unsubscribe } from 'pubsub-js';

const { publicRuntimeConfig } = getConfig();

const FORBIDDEN_PAGES_DOMAINS = ['api', 'platform', 'www'];

class MyApp extends App {
  static async getInitialProps({ Component, ctx, router }) {
    await MyApp.setDomainCookie(ctx);
    const webpageUsername = await MyApp.webpageUsername(ctx);
    const { token, redirect_path } = parseCookies(ctx);
    const redirectFromTheThirdService = !token && '/account/external_accounts' === router.route;
    if (
      !redirectFromTheThirdService &&
      !webpageUsername &&
      ![
        '/login',
        '/login-app',
        '/reset-password',
        '/contact-us',
        '/privacy-policy',
        '/terms-of-use',
        '/api/auth/callback',
        '/sso-logout'
      ].includes(router.route)
    ) {
      !ctx.store.getState().currentUser?.id && (await MyApp.fetchCurrentUser(ctx));
      !ctx.store.getState().currentAccount?.info?.id && (await MyApp.fetchCurrentAccount(ctx));
      await MyApp.updateTimezone(ctx);
    }
    if (webpageUsername) await ctx.store.dispatch(fetchWebpageInfo({ ctx, domain: webpageUsername }));

    let pageProps = { webpageUsername };
    if (Component.getInitialProps) {
      pageProps = { ...pageProps, ...(await Component.getInitialProps({ ctx, router })) };
    }
    if (redirect_path && !['/login'].includes(router.route)) {
      destroyCookie(ctx, 'redirect_path', {
        domain: publicRuntimeConfig.APP_ENV !== 'development' && `.${publicRuntimeConfig.WEBPAGE_HOST}`
      });
      if (ctx.res) {
        ctx.res.statusCode = 302;
        ctx.res.setHeader('Location', redirect_path || '/');
      } else {
        router.push(redirect_path || '/');
      }
    }
    return { pageProps };
  }

  static setDomainCookie = async ctx => {
    if (publicRuntimeConfig.APP_ENV && ['development', 'stage', 'demo'].includes(publicRuntimeConfig.APP_ENV)) {
      if (!ctx.store.getState().admin.users.active_feature_flag?.key) {
        await MyApp.fetchActiveFeatureFlag(ctx);
        setCookie(ctx, 'domain', ctx.store.getState().admin.users.active_feature_flag?.key || '', { path: '/' });
      }
    }
  };

  static webpageUsername = async ctx => {
    const result = await ctx.req?.headers?.host?.match(new RegExp(`(?:([^.]+).)${publicRuntimeConfig.WEBPAGE_HOST}`));

    return result && !FORBIDDEN_PAGES_DOMAINS.includes(result[1]) && result[1];
  };

  static fetchActiveFeatureFlag = async ctx => {
    try {
      await ctx.store.dispatch(fetchActiveFeatureFlag({ ctx }));
    } catch (err) {
      await handleUnauthorizedError({ err, ctx });
    }
  };

  static fetchCurrentUser = async ctx => {
    try {
      await ctx.store.dispatch(fetchCurrentUser(ctx));
    } catch (err) {
      await handleUnauthorizedError({ err, ctx });
    }
  };

  static fetchCurrentAccount = async ctx => {
    try {
      await ctx.store.dispatch(fetchCurrentAccount({ ctx }));
    } catch (err) {
      await handleUnauthorizedError({ err, ctx });
    }
  };

  static updateTimezone = async ctx => {
    try {
      let user = ctx.store.getState().currentUser;
      if (user.id && !user.timezone) {
        const timezone = TIMEZONE_LIST.find(el => el.name === Intl.DateTimeFormat().resolvedOptions().timeZone) || null;
        await ctx.store.dispatch(updateTimezone({ ctx, timezone: timezone?.value || timezone }));
      }
    } catch (err) {
      await handleUnauthorizedError({ err, ctx });
    }
  };

  async componentDidMount() {
    await this.props.store.dispatch(health());
    // await this.handleNotificationPermissions();
    // await this.initializeSubscriptions();
    !['/login', '/reset-password', '/contact-us', '/privacy-policy', '/terms-of-use'].includes(
      this.props.router.route
    ) &&
      this.props.pageProps?.webpageUsername === null &&
      config().HELP_BUTTON &&
      HelpButton();
  }

  componentWillUnmount() {
    // unsubscribe(this.handleNotificationTrigger);
  }

  async initializeSubscriptions() {
    subscribe('sms', (message, data) => this.handleNotificationTrigger(message, data));
  }

  handleNotificationPermissions = async () => {
    if ('Notification' in window) {
      if (window.Notification.permission !== 'granted') {
        const permission = await window.Notification.requestPermission();
      }
    }
  };

  handleNotificationTrigger = async (message, data) => {
    if ('Notification' in window) {
      if (window.Notification.permission !== 'granted') {
        const permission = await window.Notification.requestPermission();
      }

      if (window.Notification.permission === 'granted') {
        const options = {
          body: data.data,
          icon: 'https://marketaccessdirect.com/crm/images/mad/notifiy.png' // Replace with your notification icon path
        };
        new window.Notification('New Message', options);
      }
    }
  };

  get mainComponent() {
    const { Component, pageProps } = this.props;

    if (config().SSO_FEATURE) {
      return (
        <UserProvider>
          <Component {...pageProps} />
        </UserProvider>
      );
    } else {
      return <Component {...pageProps} />;
    }
  }

  render() {
    const { pageProps, store } = this.props;
    const { currentUser } = store.getState();
    const { info } = store.getState()?.public;
    const { corporation } = info;
    const profile = info.profile || currentUser.profile;
    const description = profile?.about_me?.replace(/(<([^>]+)>)/gi, '') || '';
    const { name, domain_name } = corporation || currentUser?.company || {};
    const image = profile?.personal_photo.url;
    const webpage = currentUser.webpage || info.webpage;
    if (process.env.NODE_ENV !== 'development') {
      Sentry.getCurrentScope().setTag('username', currentUser.username);
    }

    return (
      <Provider store={store}>
        <Head>
          <title>{publicRuntimeConfig.PAGE_TITLE}</title>
          <meta id="viewport" name="viewport" content="initial-scale=1.0, width=device-width" />
          {(corporation || currentUser.company) && (
            <>
              <meta name="robots" content="index, follow" />
              <meta name="description" content={description} />
              <meta
                name="keywords"
                content={`${pageProps.webpageUsername}, ${profile.first_name}, ${profile.last_name}, ${name}, ${domain_name}, medical, insurrance, mad, market, access, direct`}
              />
              <link rel="canonical" href={publicRuntimeConfig.APP_URL} />
              <meta property="og:type" content="article" />
              <meta property="og:locale" content="en_US" />
              <meta property="og:url" content={`//${webpage}`} />
              <meta property="og:title" content={publicRuntimeConfig.PAGE_TITLE} />
              <meta property="og:description" content={description} />
              <meta property="og:image" content={image} />
              <meta property="og:site_name" content={publicRuntimeConfig.PAGE_TITLE} />
            </>
          )}
        </Head>
        {this.mainComponent}
        <WebSocketConnection
          allowConnection={
            !['/login', '/reset-password', '/contact-us', '/privacy-policy', '/terms-of-use'].includes(
              this.props.router.route
            ) && this.props.pageProps?.webpageUsername === null
          }
        />

        <Dialer />
      </Provider>
    );
  }
}

export default withRedux(initStore)(MyApp);
